@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.Information {
    // height: 1400px;
    position: relative;
    background-image: url("./village.webp");
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        background: linear-gradient(180deg, #121215 2.36%, rgba(23, 21, 22, 0.92) 15.36%, #121215 110.03%);
        height: 100%;
        top: 0;
        pointer-events: none;
    }
}

.InformationContainer {
    position: relative;
    padding-top: 24px;
}

.InformationItemDescription {
    text-shadow: 0 1px 1px #020202;

    text-align: justify;
    span {
        color: #c1c13d;
        font-weight: bold;
    }
}

.InformationList {
    margin-top: 60px;
    columns: 2;
    column-gap: 40px;
}

.InformationItem {
    // width: calc(50% - 40px);
    height: 480px;
    background: linear-gradient(180deg, #131316 0%, #2f1f1b 54.63%, #5a3d28 100%);
    border: 1px solid #553042;
    margin-bottom: 40px;
    border-radius: 30px;
    padding: 25px;
    // overflow: hidden;
    // position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    &.InformationItemBig {
        height: 660px;
        background: linear-gradient(180deg, #131316 0%, #19291b 54.63%, #191e19 100%);
        border: 1px solid #151615;
    }

    
}
.InformationItemIcon {
    width: 179px;
    height: 234px;
    background: url("./warrior.png");

    &.defender {
        background: url("./deffender.png");
    }
    &.mob {
        background: url("./mob.png");
    }
    &.mobBlue {
        background: url("./mob_blue.png");
    }
    &.skeleton {
        background: url("./skeleton.png");
    }
    &.witcher {
        background: url("./witcher.png");
    }

    background-size: 100% 100% !important;

}
.InformationItemLast {
    width: 100%;
    height: 310px;
    border: 1px solid #151615;
    background: linear-gradient(180deg, #131316 0%, #19291b 54.63%, #191e19 100%);
    border-radius: 30px;
    padding: 40px;
    color: white;
    display: flex;
    position: relative;

    .InformationItemTitle {
        position: absolute;
        top: 40px;
    }
    .InformationItemIcon {
        width: 179px;
        height: 234px;
        // background: url("./npc.png");
        background-size: 100% 100%;
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .InformationItemDescription {
        position: absolute;
        bottom: 40px;
        max-width: 75%;
    }
}


@include media-breakpoint-down(lg) {
    .InformationList {
        columns: 1;
    }
}