@import "~bootstrap/scss/bootstrap.scss";

$main-color: #d75527;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.text-main-color {
  color: $main-color;
}

.nav-link {
  color: white;
  text-decoration: none;
  cursor: pointer !important;
  &:hover {
    color: $main-color;
  }
}

.bg-none {
  background: none;
}

.input-group-text, .form-control, .form-label {
  transition: all 0.15s ease-in-out;
}

.input-proj-container:focus-within {
  
  .input-group-text, .form-control {
    border: 1px solid $main-color;
  }

  .form-label {
    color: $main-color !important;
  }
}

.input-proj {
  border: 1px solid #443d3d;
  color: white;
  caret-color: $main-color;

  &:focus {
    // border: none;
    box-shadow: none;
    outline: none;
    background: none;
  }

}

.btn-main-outline {
  border: 1px solid $main-color;
  color: $main-color;
  &:hover {
    background-color: $main-color;
    color: #fff;
  }
}

.stop {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  animation-play-state: paused;
}


.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.dropdown-menu {
  background: #292017;
  border: 1px solid #443d3d;
}

.btn-secondary {
  background-color: #292017;
  border: none;
}

.btn-primary {
  background-color: #d75527;
  border: none;
}

.btn-primary:hover {
  background-color: #e66a3c;
}