
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.TokenShare {
    // height: 1666px;
    position: relative;
    background-image: url("./../../images/bkg/island.webp");

    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    // pointer-events: none;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        background: linear-gradient(180deg, #121215 7.36%, rgba(23, 21, 22, 0.96) 39.36%, #121215 80.03%);
        height: 100%;
        top: 0;
        pointer-events: none;
    }
}

.TokenSharePotionCtx {
    height: 650px;
}

.RoadMapContent {
    height: 800px;
}


.ActionContent {
    height: 700px;
}

.TokenSharePotionContent {
    position: relative;
    display: flex;
    justify-content: center;
    // align-items: center;
    height: 100%;
    top: 80px;
    .TokenSharePotion {
        width: 222px;
        height: 420px;
        background-image: url("./potion1.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        animation: scale 2.4s linear infinite;
        animation-delay: 2.6s;
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.TokenShareContainer {
    position: relative;
    padding-top: 20px;
    height: 100%;
}

.svgRoadMap {
    transform: translate(0, 0px);
    pointer-events: none;
    .svgRoadMapQList {
        .svgRoadMapQItem {
            max-width: 300px;
            background: #121215;
            padding: 8px;
            border-radius: 4px;
            color: #8b8786;
            border: 1px solid;
            font-size: 14px;
            margin-bottom: 6px;

            span {
                font-weight: bold;
                color: #eaeaea;
                font-size: 1.1em;
            }
        }
    }

    &.svgRoadMapLast {
        transform: translate(-205px, 0px);

        .svgTitlePercent {
            text-align: right;
        }
    }
}

.svgTitle {
    color: #8b8786;
    text-align: justify;
}

.svgTitlePercent {
    font-size: 40px;
    font-weight: bold;

    &.blue {
        color: #6a33e8;
    }
    &.yellow {
        color: #d0a641;
    }

    &.red {
        color: #cc3e31;
    }

    &.orange {
        color: #d75527;
    }

    &.green {
        color: #33ab5e;
    }
}

.TokenSharePotionContentAnimateMobile {
    display: none;
}


@include media-breakpoint-down(lg) {
    .TokenSharePotionContentAnimate, .TokenSharePotion {
        display: none;
    }

    .TokenSharePotionContentAnimateMobile {
        display: block;
    }
}