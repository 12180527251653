.Fotter {
    height: 100px;
    background: #040404;
}

.FotterContainer {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}


.FotterLinks {
    display: flex;
    height: 50px;
    align-items: center;
}