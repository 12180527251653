
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.Play {
    height: 210px;
    position: relative;
    border-top: 1px solid #553042;
    border-bottom: 1px solid #553042;
    width: 100%;
    background: linear-gradient(180deg, #131316 0%, #2f1f1b 54.63%, #5a3d28 100%);
}

.PlayInnerContainer {
    position: absolute;
    width: 35%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    height: 100%;
    align-items: center;
}

.PlayTitle {
    font-size: 30px;
    color: white;
    font-weight: bold;
    // position: absolute;
    // right: 0;
}

.PlayNpc {
    width: 200px;
    height: 224px;
    background: url("./warrior.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -60px;

    animation: mymove 1.5s infinite;
    
}

.PlayBtn {
    // position: absolute;
    // right: 0;
    // top: 60px;
    max-width: 210px;
}

.PlayContainer {
    height: 100%;
}


.PlayTitleAndBtn {
    position: absolute;
    right: 0px;
    display: flex;
    height: 50%;
    flex-direction: column;
    justify-content: space-between;
}
@keyframes mymove {
    50% {
      -webkit-filter: grayscale(10%) saturate(2); 
      filter: grayscale(10%) saturate(2);
    }
  }

@include media-breakpoint-down(lg) {
    .PlayInnerContainer {
        width: 80%;
    }

    .PlayBtn {
    max-width: 240px;
    }
}

@include media-breakpoint-down(xl) {
    .PlayInnerContainer {
        width: 60%;
    }

    .PlayBtn {
    max-width: 240px;
    }
}