@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.TopScreen {
    height: 994px;
    background: linear-gradient(261.66deg, #121215 5.36%, #492e1b 21.21%, #121215 65.01%, #121215 91.26%);
    position: relative;
    width: 100%;

    &::before,
    &::after {
        height: 100%;
        content: "";
        position: absolute;
        width: 100%;
        top: 0;
        pointer-events: none;
    }

    &::before {
        background-image: url("./../../images/bkg/island.webp");
        background-size: 65%;
        background-repeat: no-repeat;
        background-position: 125% 50%;
        background-attachment: fixed;
        animation: island-animate 4.2s linear infinite;
    }

    &::after {
        background: linear-gradient(186.13deg,
                rgba(18, 18, 21, 0) 19.92%,
                rgba(18, 18, 21, 0.1) 62.06%,
                rgba(18, 18, 21, 0.93) 77.33%);
    }
}

.TopScreenContainer {
    position: relative;
    height: 100%;
    width: 100%;
}

.TopScreenMainInfo {
    width: 40%;
    position: absolute;
    top: 46px;

    .TopScreenTitle {
        font-size: 60px;
        color: #f9b40a;
    }

    .TopScreenDescription {
        color: white;

        .TopScreenDescriptionAdditionalDescription {
            position: relative;
            top: 22px;
            font-size: 17px;
        }
    }
}

.ExchangeInfo {
    width: 398px;
    height: 345px;
    position: relative;
    top: 365px;
    padding: 24px;
    border-radius: 20px;
    border: 1px solid #443d3d;
    background: linear-gradient(180deg, #221f20 -15.56%, #080808 128.89%);
    color: white;
    z-index: 1;

    .ExchangeInfoTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            color: #8b8786;
            font-size: 14px;
        }
    }

    .ExchangeInfoForm {
        position: relative;
        top: 24px;
    }
}

.TopScreenInfo {
    display: flex;
    position: absolute;
    bottom: 10px;
    justify-content: space-between;
    z-index: 1;
    width: calc(100% - 24px);
    text-align: center;

    .TopScreenInfoItem {
        width: 264px;
        height: 198px;
        border: 1px solid #443d3d;
        padding: 24px;
        background: linear-gradient(180deg, #221f20 0%, #080808 100%);
        border-radius: 20px;
        position: relative;
    }

    .TopScreenInfoItemImage {
        position: absolute;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        left: 4px;
        right: 0;
        margin: auto;
        top: -42px;

        &.Registered {
            background-image: url("./npc1.png");
            height: 129px;
            width: 92px;
        }

        &.Head {
            background-image: url("./head.png");
            height: 108px;
            width: 122px;
        }

        &.Rnd {
            background-image: url("./head.png");
            height: 108px;
            width: 122px;
        }

        &.Crafted {
            background-image: url("./sword.png");
            height: 115px;
            width: 122px;
        }
    }

    .TopScreenInfoItemCount {
        font-size: 40px;
        margin-top: 60px;
        font-weight: bold;
    }

    .TopScreenInfoItemDesc {
        color: #8b8786;
    }
}

@include media-breakpoint-down(lg) {
    .TopScreen {
        height: auto;
        background: none;
        position: relative;

        &::before {
            display: none;
        }
    }

    .TopScreenMainInfo {
        width: 100%;
        position: relative;
        top: 0;
        margin-bottom: 48px;
    }

    .ExchangeInfo {
        top: 0px;
        width: 100%;
        position: relative;
    }

    .TopScreenInfo {
        flex-wrap: wrap;
        // bottom: 200px;
        position: relative;
        justify-content: space-between;
        width: 100%;
        row-gap: 12px;
        margin-bottom: 24px;

        .TopScreenInfoItem {
            margin-bottom: 12px;
            width: calc(50% - 12px);
        }
    }
}

@keyframes island-animate {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(6px);
    }

    100% {
        transform: translateY(0px);
    }
}