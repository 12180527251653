.Header {
    background: linear-gradient(180deg, #040404 0%, #221a14 100%);
    border-bottom: 1px solid #292017;;
  
   
  
    .Flag {
      height: 24px;
      width: 24px;
      display: inline-block;
      transform: scale(1.2);
    }
  
  }
  

  .FlagContainer {
    width: 120px;
    position: relative;

    & > a {
        display: flex;
        align-items: center;
    }

    .Flag {
        height: 24px;
        width: 24px;
        display: inline-block;
        transform: scale(1.2);
    }
}