.Video {
    height: 1100px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        background: linear-gradient(180deg, #121215 2.36%, rgba(23, 21, 22, 0.94) 15.36%, #121215 110.03%);
        height: 100%;
        top: 0;
        pointer-events: none;
    }
}

.VideoContainer {
    position: relative;
    padding-top: 20px;
}
