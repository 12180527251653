
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.Levels {
    height: 1050px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        background: linear-gradient(180deg, #121215 2.36%, rgba(23, 21, 22, 0.94) 15.36%, #121215 110.03%);
        height: 100%;
        top: 0;
        pointer-events: none;
    }
}

.LevelsContainer {
    position: relative;
    padding-top: 30px;
}

.LevelsInfo {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.LevelsPhone {
    width: 420px;
    height: 831px;
    pointer-events: none;

    video {
        width: 100%;
        height: 100%;
    }
}

.LevelsItemInfoLeft, .LevelsItemInfoRight {
    position: absolute;
    width: 400px;
    .LevelsItemInfoIcon {
        width: 80px;
        height: 105px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 34px;
    }

    .LevelsItemInfoDescription {
        color: #8B8786;
        font-size: 16px;
    }
}

.LevelsItemInfoRight {
    right: 0;
    top: 260px;
    .LevelsItemInfoIcon {
        width: 75px;
        height: 90px;
        background-image: url("./potion.webp");
    }
}

.LevelsItemInfoLeft {
    left: 0px;
    top: 660px;

    .LevelsItemInfoIcon {
        background-image: url("./shield.webp");
    }
}


.LevelsIsland, .LevelsCastle {
    width: 250px;
    height: 250px;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 30px;
}
.LevelsIsland {
    background-image: url("./islandForest.webp");
    transform: translate(250px, 615px);
    // transform: translate(-250px, 142px);
    
}
.LevelsCastle {
    background-image: url("./castle.webp");
    transform: translate(-280px, 160px);
}


@include media-breakpoint-down(lg) {
    .InformationList {
        height: auto;
    }
    .LevelsIsland, .LevelsCastle, .LevelsItemInfoLeft, .LevelsItemInfoRight {
        display: none;
    }
    

    .LevelsInfo {
        flex-direction: column;
        align-items: center;
    }
}